<template>
  <div class="view-root">
    <!-- <p class="common-title">会议列表</p> -->
    <!-- v-if="user_type == 'admin'"-->
    <div style="text-align: right" v-if="['organizer', 'admin'].includes(user_type)">
      <el-button type="primary" size="small" @click="gotoMeetingView('')"
        >新增会议</el-button
      >
    </div>
    <div class="flex-row-wrap" style="gap: 20px">
      <div
        v-for="(meeting, index) in dataList"
        :key="index"
        class="meeting-item"
        @click="gotoMeetingView(meeting._id.$id)"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            height: 36px;
            align-items: flex-end;
          "
        >
          <div style="display: flex; align-items: center">
            <img src="../../assets/calender.png" class="calendar" />
            <p class="text-1" style="margin-left: 5px">
              {{ formatShowDate(meeting.start_date) }} -
              {{ meeting.end_date ? formatShowDate(meeting.end_date) : "无" }}
            </p>
          </div>
          <p
            class="meeting_type"
            :style="randomStyle(index)"
            style="line-height: 36px"
          >
            {{ meeting.type }}
          </p>
        </div>
        <div style="width: 100%; padding-right: 20px; box-sizing: border-box">
          <p class="meeting-topic">{{ meeting.topic }}</p>
          <p class="meeting-name">{{ meeting.name }}</p>
          <p class="text-1 meeting-audiences">面向：{{ meeting.audiences }}</p>

          <div style="margin-top: 15px; display: flex; width: 100%">
            <div
              class="text-1"
              style="
                display: flex;
                align-items: center;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 5px;
                box-sizing: border-box;
              "
            >
              <img
                src="../../assets/address.png"
                style="
                  width: 13px;
                  height: auto;
                  object-fit: cover;
                  margin-right: 5px;
                "
              />
              <p
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ meeting.address }}
              </p>
            </div>
            <div class="text-1" style="display: flex; align-items: flex-end">
              <img
                src="../../assets/peoples.png"
                style="
                  width: 16px;
                  height: auto;
                  object-fit: cover;
                  margin-right: 5px;
                "
              />
              <p>会议规模： {{ meeting.attend_peoples }}人</p>
            </div>
          </div>
          <div style="margin-top: 15px; display: flex; width: 100%"></div>
          <div
            class="text-1"
            style="
              display: flex;
              align-items: center;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-right: 5px;
              box-sizing: border-box;
            "
          >
            <p
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              已使用参好会额度：{{
                meeting.user_usage ? meeting.user_usage : 0
              }}人次
            </p>
          </div>
          <div
            class="text-1"
            style="margin-top: 10px; display: flex; flex-direction: row-reverse"
          >
            <!-- <p class="common-btn" style="margin-left: 15px;">报名(带门票)</p> -->
            <p
              class="common-btn"
              @click.stop="clickShowCheckMeetingDialog(meeting._id.$id)"
            >
              手机预览
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showCheckMeetingDialog"
      title="会议查看"
      width="40%"
    >
      <div>
        <el-form v-model="shareInfo">
          <el-form-item label="扫码查看会议">
            <vue-qr :text="shareInfo.public_url" :size="160"></vue-qr>
          </el-form-item>
          <h3 style="margin-bottom: 15px">含门票的报名链接</h3>
          <el-form-item label="门票">
            <el-select
              v-model="shareInfo.ticket_id"
              placeholder="请选择"
              style="width: 400px"
            >
              <el-option
                v-for="(ticket, index) in tickets"
                :key="index"
                :label="
                  ticket.name +
                  ' (' +
                  ticket.number +
                  ')张 单张' +
                  ticket.price +
                  '元'
                "
                :value="ticket._id.$id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="指定参与者邮箱">
            <el-input
              v-model="shareInfo.user_email"
              placeholder="请输入指定参与者邮箱"
              style="width: 60%"
            ></el-input>
          </el-form-item>
          <el-form-item label="指定参与者手机号">
            <el-input
              v-model="shareInfo.user_mobile"
              placeholder="请输入指定参与者手机"
              style="width: 60%"
            ></el-input>
          </el-form-item>
        </el-form>

        <p v-if="shareInfo.showShareUrl" style="margin-bottom: 15px">
          {{ shareInfo.shareUrl }}
        </p>
        <div>
          <el-button type="primary" size="small" @click="createShareUrl"
            >生成门票报名链接</el-button
          >
          <el-button
            type="success"
            v-if="shareInfo.showShareUrl"
            size="small"
            @click="copyLink"
            >Copy门票链接</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import "../../assets/common/common.css";
import { getMeetings, getTickets } from "../../api/api";
import { getUserType, getUserId, setUserId } from "../../utils/store";
import { formatDate } from "../../utils/date";
import VueQr from "vue-qr";
export default {
  name: "index",
  components: {
    VueQr,
  },
  data() {
    return {
      randomColors: ["#449AFF", "#FF8A00", "#00C488", "#FF5340"],
      shareInfo: {
        public_url: "",
        meeting_id: "",
        user_email: "",
        user_mobile: "",
        ticket_id: "",
        shareUrl: "",
        showShareUrl: false,
      },
      user_type: getUserType(),
      organizer_id: "",
      dataList: [],
      showCheckMeetingDialog: false,
      tickets: [],
    };
  },
  mounted() {
    // 重置缓存
    localStorage.setItem('CUR_ORGID', '')
    localStorage.setItem('CUR_MEETING_ID', '')
    if (getUserType() == "organizer") {
      this.organizer_id = getUserId();
    } else {
      this.organizer_id = this.$route.query.id;
    }
    this.fetchData();
  },
  methods: {
    gotoMeetingView(id) {
      //从admin系统过去的，直接设置一下user_id就可以，不然获取到的数据都是不对的
      setUserId(this.organizer_id);
      this.$router.push({
        path: "/meeting",
        query: {
          meeting_id: id,
          org_id: this.organizer_id,
        },
      });
    },

    randomStyle(pos) {
      let index = pos % 4; // Math.random() * 10
      if (index <= 0) {
        index = 0;
      }
      index = parseInt(index);
      let style = {
        backgroundColor: this.randomColors[index],
      };
      return style;
    },

    requestTickets(meeting_id) {
      getTickets(meeting_id).then((res) => {
        this.tickets = res.data.data;
        this.showCheckMeetingDialog = true;
      });
    },

    copyLink() {
      let content = this.shareInfo.shareUrl;
      const input = document.createElement("input");
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      input.remove();
      this.$message.success("复制成功");
    },
    createShareUrl() {
      if (this.shareInfo.ticket_id == "") {
        this.$message.error("请选择门票");
        return;
      }
      this.shareInfo.shareUrl =
        "http://canhaohui-fwh.reachable-edu.com/meetingForm?meeting_id=" +
        this.shareInfo.meeting_id +
        "&ticket_id=" +
        this.shareInfo.ticket_id;
      if (this.shareInfo.user_email) {
        this.shareInfo.shareUrl =
          this.shareInfo.shareUrl + "&user_email=" + this.shareInfo.user_email;
      }
      if (this.shareInfo.user_mobile) {
        this.shareInfo.shareUrl =
          this.shareInfo.shareUrl +
          "&user_mobile=" +
          this.shareInfo.user_mobile;
      }
      this.shareInfo.showShareUrl = true;
    },
    clickShowCheckMeetingDialog(meeting_id) {
      this.shareInfo.public_url =
        "http://canhaohui-fwh.reachable-edu.com/meeting?meeting_id=" +
        meeting_id;
      this.shareInfo.ticket_id = "";
      this.shareInfo.meeting_id = meeting_id;
      this.shareInfo.shareUrl = "";
      this.shareInfo.user_email = "";
      this.shareInfo.user_mobile = "";
      this.shareInfo.showShareUrl = false;
      this.requestTickets(meeting_id);
    },
    fetchData() {
      getMeetings(this.organizer_id).then((res) => {
        let result = res.data.data;
        for (let i = 0; i < result.length; i++) {
          this.dataList.push(result[i]);
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
        }
        // this.dataList = res.data.data
      });
    },

    formatShowDate(time) {
      return formatDate(new Date(time * 1000), "yyyy/MM/dd");
    },
  },
};
</script>

<style lang="scss" scoped>
.meeting-item {
  cursor: pointer;
  width: 440px;
  height: 280px;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);

  .common-btn {
    border-radius: 5px;
    cursor: pointer;
    width: 80px;
    color: white;
    background-color: #449aff;
    font-size: 14px;
    text-align: center;
    padding: 8px 0px;
    box-sizing: border-box;
  }

  .calendar {
    width: 28px;
    height: auto;
    object-fit: cover;
  }

  .text-1 {
    color: #999999;
    font-size: 14px;
  }

  .meeting-address {
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .meeting-audiences {
    line-height: 24px;
    margin-top: 10px;
  }

  .meeting_type {
    color: white;
    font-size: 14px;
    box-sizing: border-box;
    padding: 0px 6px;
    text-align: center;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .meeting-topic {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin-top: 15px;
  }

  .meeting-name {
    margin-top: 15px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
}

.ql-container {
  height: 160px !important;
}

.mapsearch {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 200px;
  height: 40px;
  z-index: 20;
}

.logo-uploader {
  width: 178px;
  height: auto;
  object-fit: cover;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}

.logo-uploader2 {
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  font-size: 28px;
  color: #8c939d;
  object-fit: cover;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}
</style>
