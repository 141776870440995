<template>
  <div class="view-root">
    <h3>订单列表</h3>
    <div style="text-align: right">
      <el-label style="margin-right:20px;">可用人次额度：剩余{{ user_balance }}人次</el-label>
      <el-button type="primary" size="small" @click="newInfo()">增加订单</el-button>
    </div>
    <el-table :data="dataList" style="margin-top: 15px">
      <el-table-column type="index" prop="index" label="序号"></el-table-column>
      <el-table-column prop="organizer_name" label="客户名称"></el-table-column>
      <el-table-column prop="order_type" label="类型"></el-table-column>
      <el-table-column prop="order_amount" label="订单量"></el-table-column>
      <el-table-column prop="order_price" label="金额"></el-table-column>
      <el-table-column prop="order_currency" label="货币"></el-table-column>
      <el-table-column prop="order_status" label="订单状态"></el-table-column>

      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="editInfo(scope.row)">查看</el-button>
          <el-button type="primary" size="small" v-if="scope.row.order_status == '已支付'"
            @click="doApplyInvoice(scope.row)">申请开票</el-button>
          <el-button type="primary" size="small" v-if="scope.row.order_status == '已支付' && scope.row.order_type == '按会议'"
            @click="doShowSelectMeetingView(scope.row)">分配会议</el-button>

          <el-button v-if="scope.row.order_status == '待支付'" type="primary" size="small"
            @click="toPay(scope.row)">支付订单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showInfoDialog" title="查看订单">
      <div>
        <el-form :model="info" :rules="rules" ref="info">
          <el-form-item label="客户" prop="organizer_name">
            <el-input disabled v-model="info.organizer_name"></el-input>
          </el-form-item>

          <el-form-item label="订单类型" prop="order_type">
            <el-select disabled v-model="info.order_type" placeholder="请选择">
              <el-option v-for="item in ['按人次', '按会议']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单量" prop="order_amount">
            <el-input disabled type="number" v-model="info.order_amount"></el-input>
          </el-form-item>

          <el-form-item label="金额" prop="order_price">
            <el-input disabled type="number" v-model="info.order_price"></el-input>
          </el-form-item>

          <el-form-item label="货币" prop="order_currency">
            <el-select disabled v-model="info.order_currency" placeholder="请选择">
              <el-option v-for="item in ['人民币']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单状态" prop="order_status">
            <el-select disabled v-model="info.order_status" placeholder="请选择">
              <el-option v-for="item in ['待支付', '已取消', '已支付']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票状态" prop="invoice_status">
            <el-select disabled v-model="info.invoice_status" placeholder="请选择">
              <el-option v-for="item in ['未支付', '待开具', '已开具']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showInfoDialog = false">OK</el-button>
            <!-- <el-button type="primary" @click="saveInfo()">确 定</el-button> -->
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showNewInfoDialog" title="增加订单">
      <div>
        <el-form :model="info" :rules="rules" ref="info">

          <el-form-item label="订单类型" prop="order_type" required>
            <el-select v-model="info.order_type" placeholder="请选择" @change="orderTypeChange">
              <el-option v-for="(item, index) in ['按人次', '按会议']" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单量" prop="order_amount" required>
            <el-select v-if="info.order_type == '按人次'" v-model="info.order_amount" placeholder="请选择"
              @change="orderAmountChange">
              <el-option v-for="(item, index) in [5, 50, 100, 500, 1000, 2000, 5000]" :key="index" :value="item"
                :label="item + '人次'"></el-option>
            </el-select>
            <el-select v-if="info.order_type == '按会议'" v-model="info.order_amount" placeholder="请选择"
              @change="orderAmountChange">
              <el-option v-for="(item, index) in [10000, 20000, 50000, 100000]" :key="index" :value="item"
                :label="'一场最多' + item + '人次'"></el-option>
            </el-select>

          </el-form-item>
          <el-label>订单金额：{{ info.order_price }}元</el-label>

        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showNewInfoDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveInfo()">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showPaymentMethodDialog" title="支付订单">
      <el-form :model="info" label-width="200px">
        <el-form-item label="请选择支付方式" required>
          <el-radio-group v-model="info.payment_method">
            <el-radio :label="item" v-for="item in ['微信支付', '支付宝', '银行转帐']" :key="item">
              {{ item }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goPay">继续支付</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="showBankInfoDialog" title="银行转帐信息">
      <div>
        <div><el-label>请将订单金额转账至以下银行帐户，并备注订单编号</el-label></div>
        <div><el-label>银行名称：中国工商银行股份有限公司上海市南京西路支行</el-label></div>
        <div><el-label>银行帐户名称：树满网络科技（上海）有限公司</el-label></div>
        <div><el-label>银行帐户号码：1001 2074 0920 4844 556</el-label></div>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showNewInfoDialog = false">OK</el-button>
          </span>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showWechatPayDialog" title="扫码支付" width="35%" :before-close="handleBeforeClose">
      <div style="display: flex;align-items: center;justify-content: center;">
        <vue-qr :text="qrcode_url" :size="300"></vue-qr>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="showSelectMeetingsDialog" title="选择会议" width="45%">
      <div style="max-height: 450px">
        <el-table :data="meetingList">
          <el-table-column type="index" prop="index" label="序号"></el-table-column>
          <el-table-column prop="name" label="会议名称"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="confirmSelectMeeting(scope.row)">选择</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>


    </el-dialog>

  </div>
</template>
<script>
import {
  assignOrderToMeeting,
  getMeetings,
  getAliPayQrUrl,
  getWeChatPayQRCodeUrl,
  postRequest,
  applyInvoice,
  getOrganizerInfo
} from "../../api/api";
import "../../assets/common/common.css";
import VueTagsInput from "@johmun/vue-tags-input";
import VueQr from "vue-qr";
import {
  getUserId,
} from '../../utils/store'
export default {
  name: "index",
  components: {
    VueTagsInput,
    VueQr,
  },
  data() {
    return {
      showSelectMeetingsDialog: false,
      meetingList: [],

      qrcode_url: '',
      showWechatPayDialog: false,
      organizer_id: getUserId(),
      // session_id: this.$route.query.session_id,
      dataList: [],
      tag: "",
      tags: [],
      info: this.initInfo(),
      showInfoDialog: false,
      showNewInfoDialog: false,
      showPaymentMethodDialog: false,
      showBankInfoDialog: false,
      rules: {
        order_type: [
          { required: true, message: '请选择订单类型', trigger: 'blur' }
        ],
        order_amount: [
          { required: true, message: '请选择订单对应的购买额度', trigger: 'change' }
        ],
        // 其他验证规则
      },
      user_balance: ''
    };
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  mounted() {
    this.fetchData();
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },

  methods: {

    handleVisibilityChange() {
      if (document.hidden) {
      } else {
        this.requestOrders()
      }
    },

    async doApplyInvoice(row) {
      let orgInFoResult = await getOrganizerInfo(getUserId())
      let orgInfo = orgInFoResult.data.data
      let enableInvoice = false
      if (orgInfo.invoice_tax_num && orgInfo.invoice_title) {
        enableInvoice = true
      }
      if (enableInvoice == false) {
        this.$message.warning('请点击右上角，在组织信息中，设置开票信息')
        return
      }
      let applyResult = await applyInvoice(row._id.$id)
      if (applyResult.data.code == 0) {
        this.$message.success('申请成功')
        this.fetchData()
      } else {
        this.$message.warning(applyResult.data.msg)
      }
      // //判断组织者是否完善了发票信息
      // applyInvoice(row._id.$id).then((res) => {
      //   let result = res.data
      //   if (result.code == 0) {
      //     this.$message.success('申请成功')
      //     this.fetchData()
      //   } else {
      //     this.$message.warning(result.msg)
      //   }
      // })
    },

    confirmSelectMeeting(row) {
      this.$alert('您确定要把订单分配给当前会议吗？，分配之后，不可更改~', '提示', {
        callback: action => {
          if (action == 'confirm') {
            assignOrderToMeeting(this.info._id.$id, row._id.$id).then((res) => {
              let result = res.data
              if (result.code == 0) {
                this.$message.success('分配成功')
                this.showSelectMeetingsDialog = false
              } else {
                this.$message.warning(result.msg)
              }
            })
          }
        }
      })
    },

    doShowSelectMeetingView(row) {
      this.info = row
      this.showSelectMeetingsDialog = true
    },


    initInfo() {
      return {
        id: "",
        'organizer_id': this.organizer_id,
        'order_amount': '',//按人次 是用户具体的数量，按会议 是单个MEETING最大的CAP
        'order_type': '',//按人次, 按会议。按人次 适合小型会议，按会议 适合大型会议
        'order_currency': '',//默认RMB
        'order_price': '',//int
        'order_status': '',//待支付, 已取消, 已支付, 
        'invoice_status': '',//未支付, 待开具, 已开具, 
        'use_status': '',//待生效, 已生效
        'payment_method': '',//微信支付, 支付宝, 银行转账
        'payment_info': '',//支付的回调信息
        'payment_time': '',//支付的时间
      }
    },

    handleBeforeClose() {
      this.fetchData()
      this.showWechatPayDialog = false
    },

    async requestOrders() {
      var params = {};
      params['function'] = 'getOrders';
      params['organizer_id'] = this.organizer_id;
      let result = await postRequest(params)
      this.dataList = result.data.data;
    },

    async fetchData() {

      let meetingResult = await getMeetings(getUserId())
      this.meetingList = meetingResult.data.data

      this.requestOrders()
      // var params = {};
      // params['function'] = 'getOrders';
      // params['organizer_id'] = this.organizer_id;
      // let result = await postRequest(params)
      // this.dataList = result.data.data;


      var params = {};
      params['function'] = 'getUserBalance';
      params['organizer_id'] = this.organizer_id;
      let result1 = await postRequest(params)
      this.user_balance = result1.data.data;
    },
    editInfo(row) {
      this.info = row;
      this.showInfoDialog = true;
    },
    newInfo(row) {

      this.info = this.initInfo();
      this.info.organizer_id = this.organizer_id;
      this.info.order_type = '按人次';
      this.info.order_currency = 'RMB';
      this.info.order_status = '待支付';
      this.info.invoice_status = '未支付';
      this.info.use_status = '待生效';
      this.showNewInfoDialog = true;
    },
    saveInfo() {
      this.info['function'] = 'updateOrganizerOrder';
      postRequest(this.info).then((res) => {
        this.$message.success("保存成功");
        this.fetchData();
        this.showNewInfoDialog = false;

      });
    },
    orderTypeChange() {
      this.info.order_amount = '';
      this.info.order_price = '';
    },
    orderAmountChange() {
      var usp = 2;
      if (this.info.order_type == '按人次') {
        if (this.info.order_amount <= 1000) {
          usp = 2;
        } else if (this.info.order_amount <= 5000) {
          usp = 1.5;
        } else {
          usp = 1;
        }

      } else if (this.info.order_type == '按会议') {
        if (this.info.order_amount <= 50000) {
          usp = 0.5;
        } else {
          usp = 0.3;
        }
      }
      this.info.order_price = this.info.order_amount * usp;

    },
    toPay(row) {
      this.info = row;
      this.showPaymentMethodDialog = true;
    },
    goPay() {
      //处理支付的逻辑
      if (this.info.payment_method == '银行转帐') {
        this.showBankInfoDialog = true;
      } else if (this.info.payment_method == '微信支付') {
        getWeChatPayQRCodeUrl(this.info._id.$id).then((res) => {
          let result = res.data.data
          result = JSON.parse(result)
          if (result.code_url) {
            this.qrcode_url = result.code_url
            this.showWechatPayDialog = true
          } else {
            this.$message.warning(result.message)
          }
        })
      } else if (this.info.payment_method == '支付宝') {
        getAliPayQrUrl(this.info._id.$id).then((res) => {
          let result = res.data.data
          // const newTab = window.open()
          // const div = document.createElement('div');
          // div.innerHTML = result
          // newTab.document.body.appendChild(div);
          // newTab.document.forms.alipaysubmit.submit();

          const div = document.createElement('div');
          div.innerHTML = result
          document.body.appendChild(div);
          document.forms.alipaysubmit.submit();
        })
      }
      this.showPaymentMethodDialog = false;
    }
  },
};
</script>
<style scoped lang="scss">
.QRcode {
  width: 220px;
  height: 200px;
  background: url('../../assets/qrcode.png');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 20px;

  .text {
    width: 100%;
    word-break: break-word;
    text-align: center;
  }
}



.ql-container {
  height: 160px !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
