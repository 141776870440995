<template>
    <div class="customer">
        <div style="text-align: right">
            <p class="btn-wrap-width" style="margin-bottom: 15px;" @click="doShowAddPartnerDialog">新增客户</p>
        </div>
        <el-table :data="customerList" border style="width: 100%">
            <el-table-column prop="name" label="客户名字">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                    <el-button @click="editPartner(scope.row)" type="text" size="small">编辑</el-button>
                    <!-- <el-button type="text" size="small">编辑</el-button> -->
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="showAddPartnerDialog" :title="partyInfo.id == '' ? '添加客户' : '编辑客户'" width="55%">
            <div>
                <div>
                    <el-form :model="partyInfo" :rules="rules" ref="partyInfo">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="partyInfo.name" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="单位名称" prop="company">
                            <el-input v-model="partyInfo.company" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="登录账号" prop="master_email">
                            <el-input v-model="partyInfo.master_email" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="Logo">
                            <el-upload class="avatar-uploader" action="/" :show-file-list="false"
                                :before-upload="(file) => beforeLogoUpload(file)">
                                <img v-if="partyInfo.logo_url" :src="partyInfo.logo_url" style="object-fit: cover"
                                    class="el-icon-plus avatar-uploader-icon" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="">
                            <label>介绍</label>
                            <quill-editor class="ql-container" style="margin-bottom: 20px" ref="myTextEditor"
                                v-model="partyInfo.intro" :options="editorOption">
                            </quill-editor>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="text-align: right;margin-top: 100px;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showAddPartnerDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confirmAddPartner()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getQiniuToken,
    updatePartner,
    getOrganizerPartners,
} from '../../api/api'
import {
    getUserType,
    getUserId
} from '../../utils/store'
import '../../assets/common/common.css'
import * as qiniu from "qiniu-js";
export default ({
    name: 'customer',
    components: {
    },
    data() {
        return {
            showAddPartnerDialog: false,
            partyInfo: {
                id: "",
                name: "",
                logo_url: '',
                company: '',
                intro: '',
                master_email: '',
                organizer_id: ''
            },
            rules: {
                master_email: [{ required: true, message: "Please input", trigger: "blur" }],
                name: [{ required: true, message: "Please input", trigger: "blur" }],
                company: [{ required: true, message: "Please input", trigger: "blur" }]
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                        ["blockquote", "code-block"], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                        [{ script: "sub" }, { script: "super" }], // 上标/下标
                        [{ indent: "-1" }, { indent: "+1" }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ["small", false, "large", "huge"] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ["clean"], // 清除文本格式
                        ["link", "image", "video"], // 链接、图片、视频
                    ], // 工具菜单栏配置
                },
                placeholder: "", // 提示
                readyOnly: false, // 是否只读
                theme: "snow", // 主题 snow/bubble
                syntax: true, // 语法检测
            },
            customerList: []
        }
    },
    mounted() {
        if (getUserType() == 'organizer') {
            this.organizer_id = getUserId()
        } else {
            this.organizer_id = this.$route.query.id
        }
        this.fetchData()

    },
    methods: {

        editPartner(row) {
            this.partyInfo = {
                id: row._id.$id,
                name: row.name,
                logo_url: row.logo_url,
                company: row.company,
                intro: row.intro,
                master_email: row.master_email,
                organizer_id: row.organizer_id
            }
            this.showAddPartnerDialog = true
        },

        doShowAddPartnerDialog() {
            this.partyInfo = {
                id: "",
                name: "",
                logo_url: '',
                company: '',
                intro: '',
                master_email: '',
                organizer_id: this.organizer_id
            }
            this.showAddPartnerDialog = true
        },
        confirmAddPartner() {
            this.$refs.partyInfo.validate((val) => {
                if (val) {
                    updatePartner(this.partyInfo).then((res => {
                        this.showAddPartnerDialog = false
                        this.fetchData()
                    }))
                }
            })
        },
        async beforeLogoUpload(file) {
            const result = await getQiniuToken();
            if (result.data.code === 0) {
                const token = result.data.data;
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                );
                const observer = {
                    complete: (res) => {
                        this.partyInfo.logo_url =
                            "http://mainbucket.reachable-edu.com/" + res.key;
                    },
                };
                this.observable.subscribe(observer);
            }
        },

        fetchData() {
            getOrganizerPartners(this.organizer_id).then((response) => {
                const res = response.data;
                if (res.code === 0) {
                    this.customerList = res.data;
                } else {
                    console.log(res.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        },
    }
})
</script>

<style>
.ql-container {
    height: 160px !important;
}
</style>

<style lang="scss" scoped>
.customer {
    box-sizing: border-box;
    padding: 30px;
}
</style>