<template>
    <div class="tabsList">
        <el-tabs class="tabs" v-model="activeName">
            <el-tab-pane label="会议列表" name="meetingList">
                <Meeting />
            </el-tab-pane>
            <el-tab-pane label="品牌客户列表" name="customerList">
                <!-- <BrandParty /> -->
                <Customer />
            </el-tab-pane>
            <el-tab-pane label="订单列表" name="orderList">
                <Order />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import Meeting from  '../meeting/index.vue'
import Customer from  '../customer/index.vue'
import Order from  '../orders/client_orders.vue'
import BrandParty from '../brandParty/index.vue'


import '../../assets/common/common.css'

export default ({
    name: 'index',
    components: {
        Meeting,
        Customer,
        Order,
        BrandParty
    },
    data() {
        return {
            activeName: 'meetingList'
        }
    },
    methods: {
    }
})
</script>

<style lang="scss">
.tabsList {
    .el-tabs__header{
        margin:15px 0 0 30px!important;
    }
    .el-tabs__item{
        font-size:16px;
    }
}
</style>

<style lang="scss" scoped>
.tabsList {
 
}
</style>